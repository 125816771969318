import React from "react";

const Promo = () => {
  return (
    <>
      <section className="promo-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5">
                <h2>Porque as pessoas amam a Rentz?</h2>
                <p className="lead">
                  Por que dedicamos todos os dias para manter o padrão 5 estrelas!
                </p>
              </div>
            </div>
          </div>
          <div className="row equal">
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-face-smile text-white"></span>
                </div>
                <h5>RN Corretora</h5>
                <p>Ótimo serviço, para quem tem uma imobiliária e esta buscando um crescimento na sua carteira de locação de forma automatizada, simplificada e organizada. A empresa entende suas particuridades e se esforça para atender sua demanda. Suporte bom, e sistema atualizado constantemente. Parabéns!</p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-face-smile text-white"></span>
                </div>
                <h5>Imobiliária Express</h5>
                <p>
                  Estamos muito satisfeitos com o sistema, a empresa apresenta soluções práticas para as diversas demandas do serviço imobiliário. Possuem um fantástico atendimento e temos imenso prazer em recomendá-los a todos nossos colegas!
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-face-smile text-white"></span>
                </div>
                <h5>Grupo Matriarca</h5>
                <p>
                  Há muito tempo estava buscando um sistema que me ajudasse a fazer o controle e gestão da minha imobiliária. Hoje, tenho orgulho de dizer que encontrei a RentzApp, onde consigo automatizar vários processos, me permitindo investir mais tempo no atendimento dos meus clientes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Promo;
