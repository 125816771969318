import React from "react";
import AboutGoal from "../../components/about/AboutGoal";
// import LatestNewsOne from "../../components/blogs/LatestNewsOne";
import Clients from "../../components/brand-logo/Clients";
// import Contact from "../../components/contact/Contact";
import FeatureImg from "../../components/features/FeatureImg";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import Price from "../../components/prices/Price";
import PromoTwo from "../../components/promo/PromoTwo";
import VideoPromoTwo from "../../components/promo/VideoPromoTwo";
import TestimonialTwo from "../../components/testimonial/TestimonialTwo";
import HeroFive from "./HeroFive";
// import Screenshot from "../../components/screenshot/Screenshot";

export default function HomeFive() {
  return (
    <Layout>
      <Navbar darkBg />
      <HeroFive />
      <Clients />
      <PromoTwo />
      <AboutGoal />
      {/* <FeatureImg ImgSource="assets/img/image-18.png" /> */}
      <VideoPromoTwo />
      {/* <Screenshot hasBg /> */}
      <Price />
      {/* <LatestNewsOne bgColor="primary-bg" /> */}
      <TestimonialTwo hasBg />
      {/* <Contact /> */}
      <Footer />
    </Layout>
  );
}
