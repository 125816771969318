import React from "react";

export default function Contact({ bgColor }) {
  return (
    <>
      <section
        id="contact"
        className={`contact-us ptb-100 ${bgColor ? "gray-light-bg" : ""}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 pb-3 message-box d-none">
              <div className="alert alert-danger"></div>
            </div>
            <div className="col-md-5">
              <div className="section-heading">
                <h2>Fale conosco</h2>
                <p>
                  É muito fácil entrar em contato conosco.
                  Basta utilizar o formulário de contato ou fazer-nos uma visita
                  para tomar um café no escritório.
                </p>
              </div>
              <div className="footer-address">
                <h6>
                  <strong>Nossa Sede</strong>
                </h6>
                <p>
                  Av. Sicília, 210 - Loja 02 - Ouro Preto, Belo Horizonte - MG, 31340-400
                </p>
                <ul>
                  <li className="mb-2 d-flex align-items-center">
                    <span className="ti-mobile mr-2"></span>
                    <a href="https://api.whatsapp.com/send?phone=553135245361&text=Oi%20estou%20interessado%20em%20saber%20mais%20informa%C3%A7%C3%B5es%20a%20respeito%20da%20*RentzApp*..." target="_blank" rel="noreferrer"> +55 31 3524 5361</a>
                  </li>
                  <li>
                    <span>
                      Email:
                      <a href="mailto:contato@rentzapp.com.br">
                        {" "}contato@rentzapp.com.br
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-7">
              <form
                action="#"
                method="POST"
                id="contactForm"
                className="contact-us-form"
              >
                <h5>Entre em contato conosco rapidamente</h5>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        placeholder="Insira o nome"
                        required="required"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Digite o email"
                        required="required"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone"
                        defaultValue=""
                        className="form-control"
                        id="phone"
                        placeholder="Seu telefone"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="company"
                        defaultValue=""
                        size="40"
                        className="form-control"
                        id="company"
                        placeholder="Sua empresa"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        id="message"
                        className="form-control"
                        rows="7"
                        cols="25"
                        placeholder="Mensagem"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mt-3">
                    <button
                      type="submit"
                      className="btn solid-btn"
                      id="btnContactUs"
                    >
                      Enviar Mensagem
                    </button>
                  </div>
                </div>
              </form>
              <p className="form-message"></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
