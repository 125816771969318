import React from "react";

export default function Footer({ space }) {
  return (
    <>
      <footer className="footer-section" id="footer">
        <div
          className={`footer-top background-img-2 ${space ? "pt-150" : "pt-60"}`}
          style={{
            background:
              "url('assets/img/footer-bg.png') no-repeat center top / cover",
          }}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
                <div className="footer-nav-wrap text-white">
                  <img
                    src="assets/img/logo-white.png"
                    alt="footer logo"
                    width="120"
                    className="img-fluid mb-3"
                  />
                  <p>
                    Com mais de 20 anos de experiência, nossa equipe altamente 
                    qualificada é dedicada a desenvolver soluções excepcionais. 
                    Na RentzApp, nossa missão é ser reconhecida como a melhor 
                    parceira em software pelos nossos clientes.
                  </p>

                  {/* <div className="social-list-wrap">
                    <ul className="social-list list-inline list-unstyled">
                      <li className="list-inline-item">
                        <a href="#/" target="_blank" title="Facebook">
                          <span className="ti-facebook"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#/" target="_blank" title="Twitter">
                          <span className="ti-twitter"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#/" target="_blank" title="Instagram">
                          <span className="ti-instagram"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#/" target="_blank" title="printerst">
                          <span className="ti-pinterest"></span>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
              <div className="col-md-12 col-lg-8">
                <div className="row">
                  {/* <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Resources</h5>
                      <ul className="list-unstyled">
                        <li className="mb-2">
                          <a href="#/">Help</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Events</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Live Support</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Open Sources</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Documentation</a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  {/* <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Empresa</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2">
                          <a href="#about">Sobre nós</a>
                        </li>
                        <li className="mb-2">
                          <a href="#blog">Clientes</a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  <div className="col-sm-6 col-md-6 col-lg-6" style={{ marginLeft: 'auto' }}>
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Localização</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-location-pin mr-2"></span>
                          Av. Sicília, 210 - Loja 02 - Ouro Preto
                          <br />
                          Belo Horizonte - MG
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-mobile mr-2"></span>
                          <a href="https://api.whatsapp.com/send?phone=553135245361&text=Oi%20estou%20interessado%20em%20saber%20mais%20informa%C3%A7%C3%B5es%20a%20respeito%20da%20*RentzApp*..." target="_blank" rel="noreferrer"> +55 31 3524 5361</a>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-email mr-2"></span>
                          <a href="mailto:contato@rentzapp.com.br">
                            {" "}
                            contato@rentzapp.com.br
                          </a>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-world mr-2"></span>
                          <a href="#/"> rentzapp.com.br</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom border-gray-light mt-5 py-3">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-7">
                  <div className="copyright-wrap small-text">
                    <p className="mb-0 text-white">
                      © rentzApp, todos os direitos reservados.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5">
                  <div className="terms-policy-wrap text-lg-right text-md-right text-left">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a className="small-text" href="assets/lgpd/Termos e Condições de Uso RentzApp.pdf" target="_blank">
                          Termos de Uso
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="small-text" href="assets/lgpd/Política de Privacidade RentzApp.pdf" target="_blank">
                          Políticas de Privacidades
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
