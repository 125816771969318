import React from "react";

export default function Clients() {
  return (
    <>
      <div className="client-section mt--80 d-none d-md-block d-lg-block">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="customer-slider-wrap client-section-wrap text-center gray-light-bg shadow-lg p-5">
                <span className="shape-left"></span>
                <span className="shape-right"></span>
                <div className="text-center">
                  <p className="lead">
                    Se responder <strong className="color-secondary font-weight-bold">
                      sim
                    </strong> a uma dessas perguntas abaixo, nossa solução é para você!
                  </p>
                </div>
                <ul className="list-inline justify-content-between">
                  <li className="list-inline-item">
                    <strong className="color-secondary font-weight-bold">
                      Você Possui uma imobiliária?
                    </strong>
                  </li>
                  <li className="list-inline-item">
                    <strong className="color-primary font-weight-bold">
                      Administra Imóveis?
                    </strong>
                  </li>
                  <li className="list-inline-item">
                    <strong className="color-secondary font-weight-bold">
                      É uma contabilidade?
                    </strong>
                  </li>
                  <li className="list-inline-item">
                    <strong className="color-primary font-weight-bold">
                      Síndico do condomínio?
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
