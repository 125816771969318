import React from "react";

export default function PromoTwo() {
  return (
    <>
      <section className="promo-section mt-5 ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10">
              <div className="section-heading mb-5">
                <span className="text-uppercase color-secondary sub-title">
                  Benefícios
                </span>
                <h2 className="mb-8">
                  Quer impressionar seu cliente? Faça a escolha certa!
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6 mb-lg-0">
              <div className="card single-promo-card single-promo-hover">
                <div className="card-body">
                  <div className="pb-2">
                    <span className="ti-credit-card icon-md color-secondary"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    <h5>Cobranças</h5>
                    <p className="text-muted mb-0">
                      Sem a necessidade de remessa e retorno,
                      coloque seu faturamento no automático.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="card single-promo-card single-promo-hover mb-lg-0">
                <div className="card-body">
                  <div className="pb-2">
                    <span className="ti-receipt icon-md color-secondary"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    <h5>Contratos</h5>
                    <p className="text-muted mb-0">
                      Reajustes e renovações sem complicações e exclusiva auditoria.
                      Garanta a qualidade dos seus serviços.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="card single-promo-card single-promo-hover mb-lg-0">
                <div className="card-body">
                  <div className="pb-2">
                    <span className="ti-stats-up icon-md color-secondary"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    <h5>Aumento de Receita</h5>
                    <p className="text-muted mb-0">
                      Traga mais dinheiro para seu caixa mantendo seu faturamento e 
                      cobranças trabalhando sozinho.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="card single-promo-card single-promo-hover mb-lg-0">
                <div className="card-body">
                  <div className="pb-2">
                    <span className="ti-pin2 icon-md color-secondary"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    <h5>Fluxo de Caixa</h5>
                    <p className="text-muted mb-0">
                      Tudo foi pensado para que você possa ter ganho de tempo, 
                      escale seu negócio com a gente.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
