import React from "react";

export default function AboutGoal() {
  return (
    <>
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6">
              <div className="about-content-left section-heading">
                <span className="text-uppercase color-secondary sub-title">
                  AQUI TUDO CONSPIRA A SEU FAVOR
                </span>
                <h2>Por que a rentzapp?</h2>
                <p>
                  Porque é agradável e muito fácil de usar,
                  assim você economiza tempo para focar em fechar grandes negócios e
                  aprimorar diversas outras áreas estratégicas,
                  impulsionando todos rumo à excelência e
                  consequentemente a sua empresa ao próximo nível.
                </p>
                <div className="single-feature mb-4">
                  <div className="d-flex align-items-center mb-2">
                    <span className="ti-hand-point-up rounded mr-3 icon icon-color-1"></span>
                    <h5 className="mb-0">Super Fácil de Usar</h5>
                  </div>
                  <p>
                    Surpreendentemente simples, completamente eficaz.
                    Nossa diferença está em ser uma solução enxuta, moderna e segura.
                    Nossa interface é intuitiva e direta.
                  </p>
                </div>
                <div className="single-feature mb-4">
                  <div className="d-flex align-items-center mb-2">
                    <span className="ti-dashboard rounded mr-3 icon icon-color-2"></span>
                    <h5 className="mb-0">Melhor Custo Benefício</h5>
                  </div>
                  <p>
                    Com desempenho excepcionalmente rápido e eficiente,
                    combinado a um ecossistema nativamente integrado,
                    eliminamos a redundância de dados,
                    beneficiando toda a equipe por um valor que cabe no seu bolso.
                  </p>
                </div>

                <div className="single-feature mb-4">
                  <div className="d-flex align-items-center mb-2">
                    <span className="ti-headphone-alt rounded mr-3 icon icon-color-3"></span>
                    <h5 className="mb-0">Suporte Humanizado</h5>
                  </div>
                  <p>
                    Nosso suporte é mais que técnico, é humano.
                    Treinado para <b>ouvir primeiro</b> e; responder acertivamente 
                    as suas dúvidas ou sugestões! Como deve ser ;)
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-5">
              <div className="about-content-right">
                <img
                  src="assets/img/robo-rentz.gif"
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
