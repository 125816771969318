import React from "react";

export default function FeatureImg({ ImgSource }) {
  return (
    <>
      <div id="features" className="feature-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="section-heading text-center mb-5">
                <span className="text-uppercase color-secondary sub-title">
                  Nova Fonte de Renda
                </span>
                <h2>Corretor, você se dedica exclusivamente às vendas de imóveis?</h2>
                <p>
                  Imagine seu cliente solicitando a locação, e você,
                  que está focado nas vendas, não pode atender.
                  Agora, e se você puder transformar essa indicação em uma fonte adicional de renda?!
                  <br />Foi exatamente isso que fizemos, crie uma rede confiável com as suas indicações.
                  Estabeleça as comissões, faça a indicação e aproveite uma receita contínua enquanto
                  outras imobiliárias gerenciam o aluguel.
                </p>
              </div>
            </div>
          </div>
          <div className="row row-grid align-items-center">
            <div className="col-lg-4">
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-1 rounded-circle">
                    <span className="ti-face-smile"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Ajuste Sua Comissão</h5>
                  <p className="mb-0">
                    Estabeleça previamente a sua remuneração pela captação e pela taxa de administração do aluguel com a imobiliária parceira.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-2 rounded-circle">
                    <span className="ti-vector"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Faça uma Indicação</h5>
                  <p className="mb-0">
                    Com apenas algumas informações, você se registra e garante seus ganhos pela indicação.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-3 rounded-circle">
                    <span className="ti-wallet"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Receba suas Comissões</h5>
                  <p className="mb-0">
                    Tenha um extrato confiável e detalhado de suas comissões,
                    permitindo que você acompanhe seus ganhos e faça o resgate quando preferir.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="position-relative" style={{ zindex: 10 }}>
                <img
                  alt="placeholder"
                  src={ImgSource}
                  className="img-center img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-4 rounded-circle">
                    <span className="ti-layout-media-right"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Indicações de Confiança</h5>
                  <p className="mb-0">
                    Corretores experientes, com quem os proprietários já fizeram negócios,
                    estão indicando imóveis para você administrar.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-5 rounded-circle">
                    <span className="ti-lock"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Ganhe com Segurança</h5>
                  <p className="mb-0">
                    Ao aceitar essas indicações, você garante aos proprietários
                    que seus imóveis serão gerenciados com a mesma confiança.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-6 rounded-circle">
                    <span className="ti-eye"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Cresça com Transparência</h5>
                  <p className="mb-0">
                    Comunicamos por e-mails aos proprietários que seus imóveis
                    foram indicados e que receberão o melhor cuidado possível.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
