import React from "react";
import { Link } from "react-router-dom";

const Cta = () => {
  return (
    <>
      <section
        className="download-section pt-100 background-img"
        style={{
          background:
            "url('assets/img/app-hero-bg.jpg') no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="download-content text-white pb-100">
                <h2 className="text-white">
                  Eleve a gestão da sua imobiliária para um novo patamar.
                </h2>
                <p className="lead">
                  Empresas que se informatizam crescem até 35% mais rápido e são 10% mais produtivas do que aquelas que não usam tecnologia.
                </p>
                {/* <div className="download-btn">
                  <Link to="/" className="btn google-play-btn mr-3">
                    <span className="ti-android"></span> Google Play
                  </Link>
                  <Link to="/" className="btn app-store-btn">
                    <span className="ti-apple"></span> App Store
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="col-md-5">
              <div className="download-img d-flex align-items-end">
                <img
                  src="assets/img/success-concept.png"
                  alt="download"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cta;
