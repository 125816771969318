import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const Navbar = ({ allOptions, darkBg, classOption }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector(".header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <>
      <header className={`header ${classOption}`}>
        <nav
          className={`navbar navbar-expand-lg fixed-top ${darkBg ? "bg-transparent" : "custom-nav white-bg"
            } ${scroll > headerTop ? "affix" : ""}`}
        >
          <div className="container">
            <Link to="/" className="navbar-brand">
              {darkBg ? (
                <img
                  src="assets/img/logo-white.png"
                  width="120"
                  alt="logo"
                  className="img-fluid"
                />
              ) : (
                <img
                  src="assets/img/logo-color-1x.png"
                  width="120"
                  alt="logo"
                  className="img-fluid"
                />
              )}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="ti-menu"></span>
            </button>
            <div
              className="collapse navbar-collapse main-menu"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                {!allOptions && (<><li className="nav-item">
                  <HashLink className="nav-link" smooth to='#home'>
                    Home
                  </HashLink>
                </li>
                  <li className="nav-item">
                    <HashLink className="nav-link" smooth to='#about'>
                      Sobre Nós
                    </HashLink>
                  </li>
                  {/* <li className="nav-item">
                    <HashLink className="nav-link" smooth to="#features">
                      Corretor Parceiro
                    </HashLink>
                  </li> */}
                  <li className="nav-item">
                    <HashLink className="nav-link" smooth to="#pricing">
                      Planos e Preços
                    </HashLink>
                  </li>
                  <li className="nav-item">
                    <HashLink className="nav-link" smooth to="#footer">
                      Contatos
                    </HashLink>
                  </li></>)}
                <li className="nav-item">
                  <HashLink
                    style={{
                      borderRadius: 30,
                      fontWeight: 700,
                      paddingLeft: 30,
                      paddingRight: 30,
                      borderColor: '#f80'
                    }}
                    className="btn app-store-btn"
                    smooth to='https://app.rentzapp.com.br'
                  >
                    Login
                  </HashLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
