export default function HeroFive() {
  return (
    <>
      <section id="home" className="hero-section position-relative min-vh-100 background-video-overly flex-column d-flex justify-content-center">
        <video
          className="fit-cover w-100 h-100 position-absolute z--1"
          autoPlay
          muted
          loop
          id="myVideo"
        >
          <source
            src="../../assets/media/corporate.mp4"
            type="video/mp4"
          />
        </video>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-8 col-lg-8">
              <div className="hero-content-left text-white text-center mt-5 ptb-100">
                <h1 className="text-white">
                  Mais negócios, menos trabalho
                </h1>
                <p className="lead">
                  Potencialize ainda mais sua empresa com a nossa tecnologia. Ofereça excelência aos seus clientes e renove suas energias para alcançar todos os seus sonhos
                </p>

                <a href="https://api.whatsapp.com/send?phone=553135245361&text=Oi%20estou%20interessado%20em%20saber%20mais%20informa%C3%A7%C3%B5es%20a%20respeito%20da%20*RentzApp*..." 
                  target="_blank" rel="noreferrer" className="btn app-store-btn">
                  Fale com o nosso time pelo whatsapp
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
