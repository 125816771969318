import { Link } from "react-router-dom";

export default function LatestNewsOne({ bgColor, light }) {
  return (
    <>
      <section id="blog" className={`our-blog-section ptb-100 ${bgColor}`}>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="section-heading mb-5">
                <h2 className={`${light ? "text-black" : "text-white"}`}>
                  Ainda com dúvidas?
                </h2>
                <p className="lead">
                  Então se inspire nestas histórias de sucesso, onde pudemos
                  compartilhar conhecimento e também aprender e melhorar
                  continuamente nossos produtos. Estamos sempre com os ouvidos
                  atentos aos nossos clientes.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="single-blog-card card border-0 shadow-sm">
                <span className="category position-absolute badge badge-pill badge-primary">
                  Imobiliária
                </span>
                <img
                  src="assets/img/blog/1.jpg"
                  className="card-img-top position-relative"
                  alt="blog"
                />
                <div className="card-body">
                  <div className="post-meta mb-2">
                    <ul className="list-inline meta-list">
                      <li className="list-inline-item">Jan 21, 2019</li>
                      <li className="list-inline-item">
                        <span>45</span> Comments
                      </li>
                      <li className="list-inline-item">
                        <span>10</span> Share
                      </li>
                    </ul>
                  </div>
                  <h3 className="h5 card-title">
                    <Link to="/blog-details">
                      Uma verdadeira revolução no mercado imobiliário!
                    </Link>
                  </h3>
                  <p className="card-text text-dark">
                    A RentzApp simplificou nossa gestão de forma excepcional. Recomendo a todos...
                  </p>
                  <Link to="/blog-details" className="detail-link">
                    Saiba Mais <span className="ti-arrow-right"></span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="single-blog-card card border-0 shadow-sm">
                <span className="category position-absolute badge badge-pill badge-danger">
                  Condomínio
                </span>
                <img
                  src="assets/img/blog/2.jpg"
                  className="card-img-top position-relative"
                  alt="blog"
                />
                <div className="card-body">
                  <div className="post-meta mb-2">
                    <ul className="list-inline meta-list">
                      <li className="list-inline-item">May 26, 2019</li>
                      <li className="list-inline-item">
                        <span>30</span> Comments
                      </li>
                      <li className="list-inline-item">
                        <span>5</span> Share
                      </li>
                    </ul>
                  </div>
                  <h3 className="h5 card-title">
                    <Link to="/blog-details">Facilidade, agilidade e eficiência</Link>
                  </h3>
                  <p className="card-text text-dark">
                    A maneira como administramos nossos condomínios agora é outra...
                  </p>
                  <Link to="/blog-details" className="detail-link">
                    Saiba Mais <span className="ti-arrow-right"></span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="single-blog-card card border-0 shadow-sm">
                <span className="category position-absolute badge badge-pill badge-info">
                  Síndico Administrador
                </span>
                <img
                  src="assets/img/blog/3.jpg"
                  className="card-img-top"
                  alt="blog"
                />
                <div className="card-body">
                  <div className="post-meta mb-2">
                    <ul className="list-inline meta-list">
                      <li className="list-inline-item">Apr 25, 2019</li>
                      <li className="list-inline-item">
                        <span>41</span> Comments
                      </li>
                      <li className="list-inline-item">
                        <span>30</span> Share
                      </li>
                    </ul>
                  </div>
                  <h3 className="h5 card-title">
                    <Link to="/blog-details">A RentzApp é uma ferramenta indispensável </Link>
                  </h3>
                  <p className="card-text text-dark">
                    Como síndico de um condomínio, não poderia estar mais satisfeito...
                  </p>
                  <Link to="/blog-details" className="detail-link">
                    Saiba Mais <span className="ti-arrow-right"></span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
